import { DatabaseFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Popover, Row, Select, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import { PopupAddPromotionVip, PopupAuditPromotionVip, PopupDetailPromotionVip } from 'components/promotion.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { PROMOTION_VIP_STATE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import * as common from 'utils/common';
import { timeL2S, timeS2L } from "utils/common";

// 會員等級優惠查詢

const MEMBER_LEVEL_TYPE = [
  `${i18n.t('memberLevelPromotion')}-${i18n.t('upgradeBonus')}`,
  `${i18n.t('memberLevelPromotion')}-${i18n.t('weeklyBonus')}`,
  `${i18n.t('memberLevelPromotion')}-${i18n.t('amountMonthly')}`
]

interface SearchParams {
  Code?: string;
  MemberAccount?: string;
  // Status?: number;
  // TimeType: number;
  StartDate: string;
  EndDate: string;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);

  const [params, setParams] = useState<SearchParams>({
    // TimeType: 0,
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1])
  });
  const { data: MemberlevelRebateList, isValidating, mutate } = $get({
    url: 'api/memberlevel/reward/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        Code: formData.order,
        MemberAccount: formData.account,
        // Status: formData.status,
        // TimeType: formData.TimeMode,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1])
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setReset(!reset);
  }

  const colorChange = (type: number) => {
    switch (type) {
      case PROMOTION_VIP_STATE.received:
        return 'color-pass'
      case PROMOTION_VIP_STATE.dispatched:
        return 'color-reviewing'
      case PROMOTION_VIP_STATE.notDispatch:
        return 'color-reject'
    }
  }

  const [columns, setColumns] = useState<any>([
    {
      title: i18n.t('order'),
      dataIndex: 'Code',
      width: 250,
      show: true,
      fixed: 'left',
    },
    {
      title: i18n.t('memberAccount'),
      dataIndex: 'Account',
      width: 200,
      show: true,
    },
    {
      title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
      width: 250,
      show: true,
      render: (_: any, record: any) => (
        <>
          <span>{record.UpAccount || '-'}</span>
          <span>／</span>
          <span>{record.AgAccount || '-'}</span>
          <span>／</span>
          <span>{record.SaAccount || '-'}</span>
        </>
      )
    },
    {
      title: i18n.t('transactionType'),
      dataIndex: 'Type',
      width: 150,
      show: true,
      render: (_: any, { Type }: any) => MEMBER_LEVEL_TYPE[Type]
    },
    {
      title: i18n.t('amount'),
      dataIndex: 'bonus_amount',
      width: 200,
      show: true,
      align: 'right',
      render: (_: any, { bonus_amount }: any) => common.toFormatNumber(bonus_amount)
    },
    {
      title: i18n.t('rolloverRate'),
      dataIndex: 'ValidBetTimes',
      width: 100,
      show: true,
      align: 'right',
    },
    {
      title: i18n.t('settlementDate'),
      dataIndex: 'AuditedTime',
      width: 180,
      show: true,
      render: (val: any) => timeS2L(val)
    },
    {
      title: i18n.t('createTime'),
      dataIndex: 'CreateDate',
      width: 180,
      show: true,
      render: (val: any) => timeS2L(val)
    },
    {
      title: i18n.t('status'),
      dataIndex: 'Status',
      width: 150,
      show: true,
      fixed: 'right',
      render: (val: any) => (
        <>
          <div className={colorChange(PROMOTION_VIP_STATE.received)}>
            {i18n.t('received')}
          </div>
          <div>
            {`${i18n.t('from')} ${'System'} ${i18n.t('distribution')}`}
          </div>
        </>
      )
    },
  ]);

  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.dataIndex === key) {
        item.show = checked;
        return true;
      }
    })
    setColumns([...columns]);
  };

  return (
    <div id="container">
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: 0,
        }}>
          <Row align="middle" gutter={10} style={{gap: '1rem 0'}}>
            <Col className="w-12">
              <Form.Item name="order">
                <Input placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="account">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="TimeMode">
                <Select
                  disabled
                  className="w-full"
                  options={[
                    { value: 0, label: `${i18n.t('createTime')}` },
                  ]}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
            <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1">
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="small"
                  dataSource={
                    MemberlevelRebateList ? [
                      {
                        key: 1,
                        totalCount: MemberlevelRebateList.TotalRecord,
                        totalAmount: MemberlevelRebateList.TotalAmount
                      }
                    ] : []
                  }
                  columns={[
                    {
                      title: i18n.t('totalCount'),
                      dataIndex: 'totalCount',
                      align: "right",
                      width: '50%'
                    },
                    {
                      title: i18n.t('totalAmount'),
                      dataIndex: 'totalAmount',
                      align: "right",
                      width: '50%',
                      render: (_, { totalAmount }) => common.toFormatNumber(totalAmount)
                    },
                  ]}
                  pagination={false}
                />
              </Spin>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1" justify="end">
            <Col>
              <Popover placement="bottomRight" trigger="click" content={
                columns.map((item: any, i: number) => (
                  <div key={i}>
                    <Checkbox
                      checked={item.show}
                      onChange={e => updateColumnShow(item.dataIndex, e.target.checked)}
                    >
                      {item.title}
                    </Checkbox>
                  </div>
                ))
              }>
                <Button><DatabaseFilled /></Button>
              </Popover>
            </Col>
          </Row>
          <Spin spinning={isValidating}>
            <Table
              size="middle"
              className="mt-1"
              dataSource={
                MemberlevelRebateList?.Data.map((item: MemberlevelRebateInfo) => (
                  { key: item.Id, ...item, OperatorAccount: 'system' }
                )) || []
              }
              columns={columns.filter((item: any) => item.show) as any}
              pagination={false}
            />
            <LayoutPagination total={MemberlevelRebateList ? MemberlevelRebateList.TotalRecord : 0} setPage={setPage} page={page} />
          </Spin>
        </Form>
      </Content>

      <PopupAddPromotionVip isOpen={isModalOpenAdd} close={() => setIsModalOpenAdd(false)} />
      <PopupAuditPromotionVip isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} />
      <PopupDetailPromotionVip isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} />
    </div>
  );
};

export default PageMain;