import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Card, Col, Divider, Image, Row, Select, Space, Spin, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import depositIcon from 'assets/image/deposit.png';
import depositAmountIcon from 'assets/image/deposit_amount.svg';
import gameProfitIcon from 'assets/image/game_profit.svg';
import lossIcon from 'assets/image/loss.svg';
import profitIcon from 'assets/image/profit.svg';
import promotionIcon from 'assets/image/promotion.png';
import totalDepositIcon from 'assets/image/total_deposit.png';
import totalFeeIcon from 'assets/image/total_fee.png';
import totalWithdrawIcon from 'assets/image/total_withdraw.png';
import userQuantityIcon from 'assets/image/user_quantity.svg';
import withdrawIcon from 'assets/image/withdraw.png';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartTooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from 'chart.js';
import { DatePickerCol, LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_TYPE } from 'enum/date';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { $get } from 'services';
import { setSearchHistory } from 'slice/search.slice';
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';

// 首頁: 數據總覽

// 圖表函式庫
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend,
  PointElement,
  LineElement,
);

const PageMain: React.FC = () => {
  const site = useSite();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { permissions: $p, permissionValidating } = useAccount();
  const { dateType } = useParams();
  const _dateType = dateType ? parseInt(dateType) : null;
  
  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    _dateType || DATE_TYPE.thisMonth
  ]);

  // 取得線上人數
  const { data: OnlineMembers, isValidating } = $get({ url: 'api/report/online/members' });

  // 總代列表
  const [SAgentId, setSAgentId] = useState<string>('');
  const { data: saList } = $get({ url: 'api/agent/sa/all/list' });

  // 每個 card 的 api
  const { data: newUsers, isValidating: newUsersValidating } = $get({
    url: 'api/report/activity/member',
    params: { DateType: date[2], SAgentId }
  });

  const { data: firstDeposit, isValidating: firstDepositValidating } = $get({
    url: 'api/report/first/deposit',
    params: { DateType: date[2], SAgentId }
  });

  const { data: totalDeposit, isValidating: totalDepositValidating } = $get({
    url: 'api/report/total/deposit/amount',
    params: { DateType: date[2], SAgentId }
  });

  const { data: bonusAmount, isValidating: bonusAmountValidating } = $get({
    url: 'api/report/bonus/amount',
    params: { DateType: date[2], SAgentId }
  });

  const { data: depositInfo, isValidating: depositInfoValidating } = $get({
    url: 'api/report/deposit/info',
    params: { DateType: date[2], SAgentId }
  });

  const { data: withdrawAmount, isValidating: withdrawAmountValidating } = $get({
    url: 'api/report/total/withdraw/amount',
    params: { DateType: date[2], SAgentId }
  });

  // 調帳扣除
  const { data: withdrawInfo, isValidating: withdrawInfoValidating } = $get({
    url: 'api/report/withdraw/info',
    params: { DateType: date[2], SAgentId }
  });

  const { data: profitInfo, isValidating: profitInfoValidating } = $get({
    url: 'api/report/profit/info',
    params: { DateType: date[2], SAgentId }
  });

  // 排行區

  const [profitGame, setProfitGame] = useState<RankProvider[]>([]);
  const { data: _profitGame, isValidating: profitGameValidating } = $get({
    url: 'api/report/profit/game',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_profitGame && _profitGame.Data) {
      const data = _profitGame.Data.constructor === Array ? _profitGame.Data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        provider: item.ProviderName,
        amount: $f(item.TotalWinLossAmount),
        ProviderName: item.ProviderName,
        ProviderCode: item.ProviderCode,
        CategoryCode: item.CategoryCode
      }))
      setProfitGame(ary)
    }
  }, [_profitGame]);

  const [deficitGame, setDeficitGame] = useState<RankProvider[]>([]);
  const { data: _deficitGame, isValidating: deficitGameValidating } = $get({
    url: 'api/report/deficit/game',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_deficitGame && _deficitGame.Data) {
      const data = _deficitGame.Data.constructor === Array ? _deficitGame.Data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        provider: item.ProviderName,
        amount: $f(item.TotalWinLossAmount * -1),
        ProviderName: item.ProviderName,
        ProviderCode: item.ProviderCode,
        CategoryCode: item.CategoryCode
      }))
      setDeficitGame(ary)
    }
  }, [_deficitGame]);

  const [profitMember, setProfitMember] = useState<RankAccount[]>([]);
  const { data: _profitMember, isValidating: profitMemberValidating } = $get({
    url: 'api/report/profit/member',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_profitMember && _profitMember.Data) {
      const data = _profitMember.Data.constructor === Array ? _profitMember.Data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        account: item.MemberAccount,
        UpMemberAccount: item.UpMemberAccount,
        sa: item.SAAccount,
        ag: item.AGAccount,
        amount: $f(item.TotalWinLossAmount),
      }))
      setProfitMember(ary)
    }
  }, [_profitMember]);

  // 虧損會員
  const [deficitMember, setDeficitMember] = useState<RankAccount[]>([]);
  const { data: _deficitMember, isValidating: deficitMemberValidating } = $get({
    url: 'api/report/deficit/member',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_deficitMember && _deficitMember.Data) {
      const data = _deficitMember.Data.constructor === Array ? _deficitMember.Data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        account: item.MemberAccount,
        UpMemberAccount: item.UpMemberAccount,
        sa: item.SAAccount,
        ag: item.AGAccount,
        amount: $f(item.TotalWinLossAmount * -1),
      }))
      setDeficitMember(ary)
    }
  }, [_deficitMember]);

  // 列表標題
  const rankProfitProvider: ColumnsType<any> = [
    {
      title: i18n.t('gameProviderCategory'),
      render: (_, record: any) => `${common.specialProviderName(record.ProviderName, site.data.SiteName, record.ProviderCode)}${i18n.t(record.CategoryCode)}`
    },
    {
      title: i18n.t('profitAmount'),
      dataIndex: "amount",
      align: 'right',
      render: (val) => <span className={common.numColor01(val)}>{val}</span>
    },
  ]
  const rankLossProvider: ColumnsType<any> = [
    {
      title: i18n.t('gameProviderCategory'),
      render: (_, record: any) => `${common.specialProviderName(record.ProviderName, site.data.SiteName, record.ProviderCode)}${i18n.t(record.CategoryCode)}`
    },
    {
      title: i18n.t('lossAmount'),
      dataIndex: "amount",
      align: 'right',
      render: (val) => <span className={common.numColor01(val)}>{val}</span>
    },
  ]
  const rankProfitAccount: ColumnsType<RankAccount> = [
    {
      title: `${i18n.t('account')}`,
      dataIndex: "account",
      width: '30%',
      defaultSortOrder: "ascend",
      render: (_, { account }) => <Link to={`/member/account/${account}`}>{account}</Link>
    },
    {
      title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
      width: '40%',
      render: (_, record: any) => (
        <span>{record.UpMemberAccount || '-'}／{record.ag}／{record.sa}</span>
      )
    },
    {
      title: i18n.t('profitAmount'),
      dataIndex: "amount",
      width: '30%',
      align: 'right',
      render: (_, record: any) => (
        <span className={common.numColor01(_)}>{_}</span>
      )
    },
  ]
  const rankLossAccount: ColumnsType<RankAccount> = [
    {
      title: `${i18n.t('account')}`,
      dataIndex: "account",
      width: '30%',
      defaultSortOrder: "ascend",
      render: (_, { account }) => <Link to={`/member/account/${account}`}>{account}</Link>
    },
    {
      title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
      width: '40%',
      render: (_, record: any) => (
        <span>{record.UpMemberAccount || '-'}／{record.ag}／{record.sa}</span>
      )
    },
    {
      title: i18n.t('lossAmount'),
      dataIndex: "amount",
      width: '30%',
      align: 'right',
      render: (_, record: any) => (
        <span className={common.numColor01(_)}>{_}</span>
      )
    },
  ]

  // 圖表設定
  const [chartOptions, setChartOptions] = useState<any>({
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  });

  // 取得bar圖表資訊
  const [reportNewMemberActivityList, setReportNewMemberActivityList] = useState<TableAttributes>({
    dataSource: [],
    total: 0
  });
  // 取得line圖表資訊
  const [reportGameProfitList, setReportGameProfitList] = useState<TableAttributes>({
    dataSource: [],
    total: 0
  });

  // 日期圖表-新用戶人數
  const { data: _reportNewMemberList,  isValidating: reportNewMemberListValidating} = $get({
    url: 'api/report/date/new/member/list',
    params: {
      SAgentId,
      StartDate: common.timeL2S(date[0] as any),
      EndDate: common.timeL2S(date[1] as any),
    }
  });

  // 日期圖表-活躍人數/投注人數
  const { data: _reportActivityMemberList,  isValidating: reportActivityMemberListValidating} = $get({
    url: 'api/report/date/activity/member/list',
    params: {
      SAgentId,
      StartDate: common.timeL2S(date[0] as any),
      EndDate: common.timeL2S(date[1] as any),
    }
  });

  // 日期圖表-遊戲盈利
  const { data: _reportGameProfitList,  isValidating: reportGameProfitListValidating} = $get({
    url: 'api/report/date/game/profit/list',
    params: {
      SAgentId,
      StartDate: common.timeL2S(date[0] as any),
      EndDate: common.timeL2S(date[1] as any),
    }
  });

  // 新用戶人數/活躍人數/投注人數 圖表資料整合
  const [chartObject, setChartObject] = useState<any>({});
  useEffect(() => {
    if (_reportNewMemberList?.Data) {
      setChartObject((currentValueObject: any) => {
        const temp: any = {};
        // api的每一個項目(日期)
        _reportNewMemberList?.Data.forEach((item: any) => {
          // 將api的值合併到原本的資料中
          temp[item.Date] = Object.assign(
            currentValueObject[item.Date] || {},
            {
              NewMemberCount: item.NewMemberCount,
            }
          )
        })
        // 更新
        return temp;
      })
    }
    if (_reportActivityMemberList?.Data) {
      setChartObject((currentValueObject: any) => {
        const temp: any = {};
        _reportActivityMemberList?.Data.forEach((item: any) => {
          temp[item.Date] = Object.assign(
            currentValueObject[item.Date] || {},
            {
              ActiveMemberCount: item.ActiveMemberCount,
              TotalMemberCount: item.ActiveMemberCount
            }
          )
        })
        return temp;
      })
    }
  }, [_reportNewMemberList, _reportActivityMemberList]);
  // 更新圖表
  useEffect(() => {
    const data = Object.entries(chartObject).map(([date, item]: any, i: number) => {
      const _days = date.split('-');

      return {
        labels: `${_days[1]}-${_days[2]}`,
        data1: item.NewMemberCount,
        data2: item.ActiveMemberCount,
        data3: item.TotalMemberCount,
      };
    })
    setReportNewMemberActivityList({
      dataSource: data,
      total: data.length
    })
  }, [chartObject]);

  // 遊戲盈利 圖表資料整合
  useEffect(() => {
    if (_reportGameProfitList?.Data) {
      const data = _reportGameProfitList?.Data.map((item: any, i: number) => {
        const _days = item.Date.split('-')

        return {
          labels: `${_days[1]}-${_days[2]}`,
          data: item.TotalWinLossAmount,
        };
      })
      setReportGameProfitList({
        dataSource: data
      })
    } else {
      setReportGameProfitList({
        dataSource: [],
        total: 0
      })
    }
  }, [_reportGameProfitList]);

  // 新用戶數/活躍人數/投注人數
  const [barData, setBarData] = useState<any>({ labels: [], datasets: [] });
  // 遊戲盈利
  const [lineData, setLineData] = useState<any>({ labels: [], datasets: [] });

  // 新用戶數/活躍人數/投注人數圖表 dataSource
  useEffect(() => {
    if (reportNewMemberActivityList?.dataSource) {
      setBarData({
        labels: reportNewMemberActivityList.dataSource.map(a => a.labels),
        datasets: [
          {
            label: i18n.t('newUsers'),
            data: reportNewMemberActivityList.dataSource.map(a => a.data1),
            backgroundColor: '#AFC8EA',
          },
          {
            label: i18n.t('activeMembers'),
            data: reportNewMemberActivityList.dataSource.map(a => a.data2),
            backgroundColor: '#CBEAD9',
          },
          {
            label: i18n.t('bettingMembers'),
            data: reportNewMemberActivityList.dataSource.map(a => a.data3),
            backgroundColor: '#E79F9C',
          },
        ],
      });
    }
  }, [reportNewMemberActivityList]);

  // 遊戲盈利圖表 dataSource
  useEffect(() => {
    setLineData({
      labels: reportGameProfitList.dataSource?.map(a => a.labels),
      datasets: [
        {
          label: i18n.t('gameProfit'),
          data: reportGameProfitList.dataSource?.map(a => a.data),
          borderColor: '#4874AD',
          backgroundColor: '#4874AD',
        },
      ],
    });
  }, [reportGameProfitList]);

  // 彩色卡
  const colorCard = (color: string, loading: boolean | undefined, icon: any, text: string, amount: number, people: number, count: number) => (
    <Col span={8}>
      <Card className={`card-shadow ${color}`} loading={loading} styles={{ body: { minHeight: 170, maxHeight: 170, padding: "12px 24px", display: "flex", alignItems: "center" } }}>
        <Row className="color-01" justify="space-between" align="middle">
          <Col span={8}>
            <Image src={icon} preview={false} />
          </Col>
          <Col span={16} className="text-end">
            <Row justify="end">
              <Col span={24}>
                <Row align="middle" justify="end">
                  <Col className="size-12 font-w-md">{i18n.t(text)}</Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row align="middle" justify="end">
                  <Col span={24} className="size-18">{$f(amount)}</Col>
                </Row>
              </Col>
              <Col span={24} className="mt-1">
                <Space direction="horizontal" className="size-12" size={2}>
                  <div>{$f(people, 0)} {i18n.t('people')}｜</div>
                  <div>{$f(count, 0)} {i18n.t('counts')}</div>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  )

  // 排行
  const tableCard = (loading: boolean | undefined, icon: any, title: string, background: string, columns: any, dataSource: any) => (
    <Col span={6}>
      <Card className="card-shadow" loading={loading}
        title={
          <Row align="middle" gutter={[12, 12]}>
            <Col><Image src={icon} preview={false} /></Col>
            <Col className="size-16">{i18n.t(title)}</Col>
          </Row>
        }
        styles={{ header: { background, color: '#fff' }, body: { padding: 0 } }}>
        <Table
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          scroll={{ y: 270 }}
        />
      </Card>
    </Col>
  )

  return (
    <div id="container">
      <Spin spinning={permissionValidating}>
        <Content>
          <Row align="middle" gutter={[16, 16]}>
            {/* 帳號與日期選擇 */}
            <Col span={24}>
              <Row align="middle" justify="space-between">
                {/* 靠左 */}
                <Col>
                  <Row gutter={[12, 12]} align="middle">
                    <Col>
                      <Select
                        showSearch
                        filterOption={common.selectFuzzyFilter}
                        className="w-12"
                        placeholder={`${i18n.t('generalAgent')}`}
                        options={[
                          { value: '', label: `${i18n.t('ALL')}` },
                          ...((saList && saList.Data) || []).map((item: any) => (
                            { value: item.Id, label: item.Account }
                          ))
                        ]}
                        onChange={setSAgentId}
                      />
                    </Col>
                    {/* 時間區間 */}
                    <DatePickerCol date={date} setDate={setDate} displayCom={['Radio']}
                      defaultDateType={_dateType || DATE_TYPE.thisMonth} />
                  </Row>
                </Col>
                {/* 靠右 */}
                <Col>
                  <Row justify="end" align="middle">
                    {$p('60401') &&
                      <Button style={{ background: '#F7AE4F' }} className="color-01 mr-1"
                        onClick={() => navigate('/report/game')}>
                        {i18n.t('gameStatisticalAnalysis')}
                      </Button>}
                    {$p('60301') &&
                      <Button style={{ background: '#4FCAB4' }} className="color-01 mr-1"
                        onClick={() => navigate('/report/business')}>
                        {i18n.t('operationReports')}
                      </Button>}
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* 在線人數 */}
            <Col span={24}>
              <Row align="middle">
                <Col className="size-16">{i18n.t('dataOverview')}</Col>
                <Spin spinning={isValidating}>
                  <Col className="online-num center" style={{ cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(setSearchHistory({ member: null }));
                      navigate(`/member?isOnline=1`);
                    }}>
                    {i18n.t('onlineMembers')} {$f(OnlineMembers?.Data?.Member || 0, 0)}
                  </Col>
                </Spin>
              </Row>
            </Col>
            {/* 第一排 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]} justify="space-between">
                {/* 新用戶數 */}
                <Col span={10}>
                  <Card className="card-shadow" loading={newUsersValidating}>
                    <Row align="middle" justify="space-around">
                      <Col><Image src={userQuantityIcon} preview={false} /></Col>
                      <Col>
                        {/* 新用戶數 */}
                        <Space direction="vertical" align="center">
                          <div className="size-14 color-03">{i18n.t('newUsers')}</div>
                          <div className="size-28 color-04 font-w-md">{$f(newUsers?.Data.NewMemberCount, 0)}</div>
                        </Space>
                      </Col>
                      <Divider className="h-3" type="vertical" />
                      <Col>
                        {/* 活躍人數 */}
                        <Space direction="vertical" align="center">
                          <div className="size-14 color-03">
                            <Row align="middle">
                              <Col>{i18n.t('activeMembers')}</Col>
                              <Col>
                                <Tooltip placement="top" title={i18n.t('membersWithBets')}>
                                  <InfoCircleFilled style={{ marginLeft: 2 }} />
                                </Tooltip>
                              </Col>
                            </Row>
                          </div>
                          <div className="size-28 color-04 font-w-md">{$f(newUsers?.Data.ActiveMemberCount, 0)}</div>
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {/* 首存金額 */}
                <Col span={14}>
                  <Card className="card-shadow" loading={firstDepositValidating}>
                    <Row align="middle" justify="space-around">
                      <Col><Image src={depositAmountIcon} preview={false} /></Col>
                      <Col>
                        <Space direction="vertical" align="center">
                          <div className="size-14 color-03">{i18n.t('firstDepositAmount')}</div>
                          <div className="size-28 color-04 font-w-md">{$f(firstDeposit?.Data.TotalFirstDepositAmount)}</div>
                        </Space>
                      </Col>
                      <Divider className="h-3" type="vertical" />
                      <Col>
                        <Space direction="vertical" align="center">
                          <div className="size-14 color-03">{i18n.t('firstDepositMembers')}</div>
                          <div className="size-28 color-04 font-w-md">{$f(firstDeposit?.Data.TotalFirstTimeDepositMemberCount, 0)}</div>
                        </Space>
                      </Col>
                      <Divider className="h-3" type="vertical" />
                      <Col>
                        <Space direction="vertical" align="center">
                          <div className="size-14 color-03">{i18n.t('redepositMembers')}</div>
                          <div className="size-28 color-04 font-w-md">{$f(firstDeposit?.Data.TotalSecondTimeDepositMemberCount, 0)}</div>
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            {/* 彩卡區 */}
            <Col span={24}>
              <Row align="middle" justify="space-between" gutter={[16, 16]}>
                {/* 彩卡區 */}
                <Col span={16}>
                  <Row align="middle" gutter={[16, 16]}>
                    {/* 總存款金額 */}
                    {colorCard('card-bg-01', totalDepositValidating, totalDepositIcon, 'totalDepositAmount',
                      totalDeposit?.Data.TotalDepositAmount, totalDeposit?.Data.TotalMemberCount, totalDeposit?.Data.TotalDepositRecordsCount)}
                    {/* 優惠金額 */}
                    <Col span={8}>
                      <Card className="card-shadow card-bg-02" loading={bonusAmountValidating}
                        styles={{ body: { minHeight: 170, maxHeight: 170, padding: "12px 24px", display: "flex", alignItems: "center" } }}>
                        <Row className="color-01" justify="space-between" align="middle">
                          <Col span={8}><Image src={promotionIcon} preview={false} /></Col>
                          <Col span={16} className="text-end">
                            <Row justify="end">
                              <Col span={24}>
                                <Row align="middle" gutter={[5, 0]} justify="end">
                                  <Col className="size-12 font-w-md">{i18n.t('promotionAmount')} /</Col>
                                  <Col className="size-12 font-w-md">{i18n.t('adjustmentPromotion')}</Col>
                                </Row>
                              </Col>
                              <Col span={24}>
                                <Row align="middle" justify="end">
                                  <Col span={24} className="size-18">{$f(bonusAmount?.Data.TotalBonusAmount)} /</Col>
                                  <Col span={24} className="size-18" style={{ paddingRight: '0.6rem' }}>
                                    {$f(bonusAmount?.Data.TotalBonusAdjustAmount)}
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={24} className="size-12">
                                <Row align="middle" justify="end">
                                  <Col className="size-12 text-end">
                                    <div>
                                      <span>{$f(bonusAmount?.Data.TotalBonusMemberCounts, 0)} {i18n.t('people')}｜</span>
                                      <span>{$f(bonusAmount?.Data.TotalBonusRecords, 0)} {i18n.t('counts')}／</span>
                                    </div>
                                  </Col>
                                  <Col className="size-12 text-end">
                                    <div>
                                      <span>{$f(bonusAmount?.Data.TotalBonusAdjustMemberCounts, 0)} {i18n.t('people')}｜</span>
                                      <span>{$f(bonusAmount?.Data.TotalBonusAdjustRecords, 0)} {i18n.t('counts')}</span>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    {/* 調帳添加*/}
                    {colorCard('card-bg-03', depositInfoValidating, depositIcon, 'adjustmentAccounting', 
                      depositInfo?.Data.TotalAdjustPlusAmount, depositInfo?.Data.TotalAdjustPlusMemberCount, depositInfo?.Data.TotalAdjustPlusRecordsCount)}
                    {/* 總出款金額 */}
                    {colorCard('card-bg-04', withdrawAmountValidating, totalWithdrawIcon, 'totalWithdrawalAmount', 
                      withdrawAmount?.Data.TotalWithdrawAmount, withdrawAmount?.Data.TotalMemberCount, withdrawAmount?.Data.TotalWithdrawRecordsCount)}
                    {/* 總出款手續費 */}
                    {colorCard('card-bg-05', withdrawAmountValidating, totalFeeIcon, 'totalWithdrawalFee', 
                      withdrawAmount?.Data.TotalWithdrawHandlingFeeAmount, withdrawAmount?.Data.TotalMemberCount, withdrawAmount?.Data.TotalWithdrawRecordsCount)}
                    {/* 調帳扣除 */}
                    {colorCard('card-bg-06', withdrawInfoValidating, withdrawIcon, 'adjustmentDeduction', 
                      withdrawInfo?.Data.TotalAdjustMinusAmount, withdrawInfo?.Data.TotalMemberCount, withdrawInfo?.Data.TotalAdjustMinusRecordsCountt)}
                  </Row>
                </Col>
                {/* 會員輸贏 */}
                <Col span={8}>
                  <Card className="card-shadow" loading={profitInfoValidating}
                    styles={{ body: { paddingLeft: 5, paddingRight: 5, paddingTop: 1, paddingBottom: 1, height: 360 } }}>
                    <Row>
                      <Col span={24} className="pr-1 pl-1 pt-1">
                        <Row align="middle" justify="space-between">
                          <Col><Image src={gameProfitIcon} preview={false} /></Col>
                          <Col className="text-end">
                            <Space direction="vertical">
                              <div className="size-14 color-03">{i18n.t('memberWinLoss')}</div>
                              <div className="size-30 font-w-md color-04">{$f(profitInfo?.Data.TotalWinLossAmount)}</div>
                            </Space>
                          </Col>
                        </Row>
                      </Col>
                      <Divider style={{ marginTop: 14, marginBottom: 14 }} />
                      <Col span={24} className="pr-1 pl-1 pb-1">
                        <Row>
                          <Col span={24}>
                            <Row align="middle" justify="space-between">
                              <Col className="size-14 color-03">{i18n.t('bettingMembers')}</Col>
                              <Col className="size-22 font-w-sm color-04">{$f(profitInfo?.Data.TotalMemberCount, 0)}</Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row align="middle" justify="space-between">
                              <Col className="size-14 color-03">{i18n.t('totalBets')}</Col>
                              <Col className="size-22 font-w-sm color-04">{$f(profitInfo?.Data.TotalBetCount, 0)}</Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row align="middle" justify="space-between">
                              <Col className="size-14 color-03">{i18n.t('totalBet')}</Col>
                              <Col className="size-22 font-w-sm color-04">{$f(profitInfo?.Data.TotalBetAmount)}</Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row align="middle" justify="space-between">
                              <Col className="size-14 color-03">{i18n.t('betAmountValid')}</Col>
                              <Col className="size-22 font-w-sm color-04">{$f(profitInfo?.Data.TotalValidBetAmount)}</Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row align="middle" justify="space-between">
                              <Col className="size-14 color-03">{i18n.t('totalPayout')}</Col>
                              <Col className="size-22 font-w-sm color-04">{$f(profitInfo?.Data.TotalSettleAmount)}</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            {/* 數據圖表 */}
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Card className="card-shadow" loading={reportActivityMemberListValidating && reportNewMemberListValidating}>
                    <Row>
                      <Col span={24}><Bar options={chartOptions} data={barData} /></Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card className="card-shadow" loading={reportGameProfitListValidating}>
                    <Row>
                      <Col span={24}><Line options={chartOptions} data={lineData} /></Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            {/* 排行 */}
            <Col span={24}>
              <Row justify="space-between" gutter={[16, 16]}>
                {/* 盈利遊戲: 資料庫是會員角度 故站長盈虧串api故意對調 */}
                {tableCard(deficitGameValidating, profitIcon, 'profitableGames', '#FF5252', rankProfitProvider, deficitGame)}
                {/* 虧損遊戲: 資料庫是會員角度 故站長盈虧串api故意對調 */}
                {tableCard(profitGameValidating, lossIcon, 'losingGames', '#00DA8B', rankLossProvider, profitGame)}
                {/* 盈利會員 */}
                {tableCard(profitMemberValidating, profitIcon, 'profitableMembersTop20', '#FF5252', rankProfitAccount, profitMember)}
                {/* 虧損會員 */}
                {tableCard(deficitMemberValidating, lossIcon, 'losingMembersTop20', '#00DA8B', rankLossAccount, deficitMember)}
              </Row>
            </Col>
          </Row>
        </Content>
      </Spin>
    </div >
  );
};

export default PageMain;
