import PageAdvanced from "pages/admin/advanced";
import PageAdvancedAddAnnouncement from "pages/admin/advanced/add_announcement";
import PageAdvancedAddSystemAnnouncement from "pages/admin/advanced/add_system_announcement";
import PageAdvancedAnnouncement from "pages/admin/advanced/announcement";
import PageAdvancedSystemAnnouncement from "pages/admin/advanced/announcement_system";
import PageAdvancedEditAnnouncement from "pages/admin/advanced/edit_announcement";
import PageAdvancedEditSystemAnnouncement from "pages/admin/advanced/edit_system_announcement";
import PageAdvancedGameManagement from "pages/admin/advanced/game";
import PageAdvancedLoginRecord from "pages/admin/advanced/login-record";
import PageAdvancedMaintenance from "pages/admin/advanced/maintenance";
import PageAdvancedOperation from "pages/admin/advanced/operation";
import PageAdvancedOperationNew from "pages/admin/advanced/operation_new";
import PageAdvancedPermissionBackOfficeAccount from "pages/admin/advanced/permission_backOfficeAccount";
import PageAdvancedPermissionName from "pages/admin/advanced/permission_name";
import PageAdvancedPermissionNameEdit from "pages/admin/advanced/permission_name_edit";
import PageAdvancedGameData from "pages/admin/advanced/platform_gameData";
import PageAdvancedGameList from "pages/admin/advanced/platform_gameList";
import PageAdvancedGameRebate from "pages/admin/advanced/platform_gameRebate";
import PageAdvancedLicense from "pages/admin/advanced/platform_license";
import PageAdvancedProviderSort from "pages/admin/advanced/platform_providerSort";
import PageAdvancedCustomerServiceSoftwareSetting from "pages/admin/advanced/platform_customerServiceSoftwareSetting";

export const advancedModule = [
  { path: '/advanced', element: <PageAdvanced/> },
  { path: '/advanced/platform_gameData', element: <PageAdvancedGameData/> },
  { path: '/advanced/platform_providerSort', element: <PageAdvancedProviderSort/> },
  { path: '/advanced/platform_gameList', element: <PageAdvancedGameList/> },
  { path: '/advanced/platform_gameRebate', element: <PageAdvancedGameRebate/> },
  { path: '/advanced/platform_customerServiceSoftwareSetting', element: <PageAdvancedCustomerServiceSoftwareSetting/> },
  { path: '/advanced/permission_name', element: <PageAdvancedPermissionName/> },
  { path: '/advanced/permission_name_edit/:id', element: <PageAdvancedPermissionNameEdit/> },
  { path: '/advanced/permission_backOfficeAccount/:id?', element: <PageAdvancedPermissionBackOfficeAccount/> },
  { path: '/advanced/announcement', element: <PageAdvancedAnnouncement/> },
  { path: '/advanced/announcement_system', element: <PageAdvancedSystemAnnouncement/> },
  { path: '/advanced/maintenance', element: <PageAdvancedMaintenance/> },
  { path: '/advanced/operation', element: <PageAdvancedOperation/> },
  { path: '/advanced/operation_new', element: <PageAdvancedOperationNew/> },
  { path: '/advanced/announcement/add', element: <PageAdvancedAddAnnouncement/> },
  { path: '/advanced/announcement/edit/:id', element: <PageAdvancedEditAnnouncement/> },
  { path: '/advanced/announcement/system/add', element: <PageAdvancedAddSystemAnnouncement/> },
  { path: '/advanced/announcement/system/edit/:id', element: <PageAdvancedEditSystemAnnouncement/> },
  { path: '/advanced/platform_license', element: <PageAdvancedLicense/> },
  { path: '/advanced/login-record', element: <PageAdvancedLoginRecord/> },
  { path: '/advanced/game/management', element: <PageAdvancedGameManagement/> },
]