import { Button, Col, Form, Input, Row, Select, Table, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { InquiryWindow, LayoutNav, LayoutPagination } from "components/layout.component";
import { ENABLE, MEMBER_GROUP_LOCK_STATUS, MEMBER_GROUP_LOCK_STATUS_YES_NONE } from "enum/state";
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from "react";
import { $get, $post } from 'services';
import * as common from 'utils/common';
import { enumToOptions, timeS2L } from "utils/common";
import { useParams } from "react-router-dom";
import { PopupModifyGroupsFail } from "components/member.component";


// 會員群組-會員列表

const PageMain: React.FC = () => {
  const { id } = useParams();
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);

  const [params, setParams] = useState<any>({
    Id: id
  });

  // 取得會員群組 會員列表
  const { data: memberGroupMemberList, isValidating , mutate} = $get({
    url: 'api/membergroup/member/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const onFinish = (formData: any) => {
    setParams({
      Id: id,
      MemberAccount: formData.MemberAccount,
      IsLockMemberGroup: formData.IsLockMemberGroup,
    })
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
  }

  // 聖經欄位顯示設定
  const columns: any = [
    {
      dataIndex: 'Account',
      title: i18n.t('memberAccount'),
      align: 'left',
      width: 180,
    },
    {
      dataIndex: 'IsLockMemberGroup',
      title: i18n.t('lockMemberGroup'),
      width: 180,
      align: 'center',
      render: (val: any) => i18n.t(MEMBER_GROUP_LOCK_STATUS_YES_NONE[val])
    },
    {
      dataIndex: 'DepositRecordsCount',
      title: i18n.t('numberOfDeposits'),
      width: 180,
      align: 'center',
      render: (val: any) => common.isNumber(val) ? common.toFormatNumber(val, 0) : '-'
    },
    {
      dataIndex: 'DepositAmount',
      title: i18n.t('totalDeposit'),
      align: 'right',
      width: 180,
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'WithdrawRecordsCount',
      title: i18n.t('numberOfWithdrawals'),
      align: 'center',
      width: 180,
      render: (val: any) => common.isNumber(val) ? common.toFormatNumber(val, 0) : '-'
    },
    {
      dataIndex: 'WithdrawAmount',
      title: i18n.t('withdrawTotalAmount'),
      width: 180,
      align: 'right',
      render: (val: any) => common.isNumber(val) ? common.toFormatNumber(val) : '-'
    },
    {
      dataIndex: 'CreateDate',
      title: i18n.t('createTime'),
      width: 180,
      align: 'center',
      render: (val: any) => timeS2L(val)
    },
  ]

// 表格的選取功能相關
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedIsLock, setSelectedIsLock] = useState<any>([]);
  const [selectedLockedAccounts, setSelectedLockedAccounts] = useState([]);
  const [selectedUnLockedAccountsId, setSelectedUnLockedAccountsId] = useState([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {

    setSelectedRowKeys(newSelectedRowKeys);

    const mappedData = memberGroupMemberList?.Data
      ?.map((item: any) => ({ key: item.Id, ...item }))
      ?.filter((item: any) => newSelectedRowKeys.includes(item.key));

    // 所選的鎖定狀態
    const selectedIsLock = mappedData?.map((item: any) => item.IsLockMemberGroup);
    setSelectedIsLock(selectedIsLock);

    // 所選且鎖定的會員帳號
    const selectedLockedAccounts = mappedData
      ?.filter((item: any) => item.IsLockMemberGroup === MEMBER_GROUP_LOCK_STATUS.lock)
      ?.map((item: any) => item.Account);
    setSelectedLockedAccounts(selectedLockedAccounts);

    // 所選且未鎖定的會員 Id
    const selectedUnLockedAccountsId = mappedData
      ?.filter((item: any) => item.IsLockMemberGroup === MEMBER_GROUP_LOCK_STATUS.unlock)
      ?.map((item: any) => item.Id);
    setSelectedUnLockedAccountsId(selectedUnLockedAccountsId);
  };

//修改會員群組相關
  const [dataList, setDataList] = useState<any>([])

  const { data: memberGroupAllList } = $get({
    url: 'api/membergroup/all/list',
    showMessage: false
  })

  // 只呈現狀態為啟用且非自己本身的會員群組
  useEffect(() => {
    if (memberGroupAllList?.Data) {
      const transformedData = memberGroupAllList?.Data
        ?.filter((item: any) => item.Status === ENABLE.enabled && item.Id !== Number(id))
        ?.map((item: any) => (
          { value: item.Id, label: item.Name }
        ))
      setDataList(transformedData);
    }
  }, [memberGroupAllList]);

  const [isModalOpenModifyGroupsFail, setIsModalOpenModifyGroupsFail] = useState(false);
  const [lockedAccount, setLockedAccount] = useState("");
  const [selectTargetGroupId, setSelectTargetGroupId] = useState<any>();
  const [selectTargetGroupName, setSelectTargetGroupName] = useState<any>();

  const handleModifyGroupCallApi=()=>{
    const send: any = {
      SourceId: Number(id),
      GroupId: selectTargetGroupId,
      MemberIds: selectedUnLockedAccountsId
    }
    $post({
      url: 'api/membergroup/member/change',
      send,
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        handleCancelModifyGroup()
        setIsModalOpenModifyGroupsFail(false);
      },
    })
  }

  const [isModifyGroupModalOpen, setIsModifyGroupModalOpen] = useState(false);

  const handleModifyGroup = () => {
    if (selectedIsLock.includes(MEMBER_GROUP_LOCK_STATUS.lock)) {
      setIsModalOpenModifyGroupsFail(true);
      setLockedAccount(selectedLockedAccounts.join('、'));
    }
    else {
      handleModifyGroupCallApi();
    }
  }

  const handleCancelModifyGroup = () => {
    setSelectTargetGroupId(undefined);
    setSelectedRowKeys([]);
    setIsModifyGroupModalOpen(false);
  };

  const handleCancelModifyGroupNoResetTargetGroupId = () => {
    setSelectedRowKeys([]);
    setIsModifyGroupModalOpen(false);
  };

// 鎖定/解鎖會員群組相關
  const [lockStatus, setLockStatus] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLockStatus = () => {
    handleCancelStatus();
    if (selectedRowKeys.length === 0) return;
    $post({
      url: 'api/membergroup/member/lock/update',
      send: {
        MemberIds: selectedRowKeys,
        IsLockMemberGroup: lockStatus,
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      }
    })
  };

  const handleCancelStatus = () => {
    setLockStatus(undefined);
    setSelectedRowKeys([]);
    setIsModalOpen(false);
  };

  return (
    <>
      <div id="container">
        <Content className="ph-2 pt-1">
          <Form form={form} onFinish={onFinish} initialValues={{
            TimeCondition: 0,
          }}>
            {/* 搜尋第一排 */}
            <Row gutter={[12, 12]} align="middle">
              <Col span={24}>
                <div className="size-18">
                  <span>{i18n.t('groupName')} : </span>
                  <span>{memberGroupMemberList && memberGroupMemberList.MemberGroupName}</span>
                </div>
              </Col>
              <Col className="w-12">
                <Form.Item name="MemberAccount">
                  <Input placeholder={`${i18n.t('memberAccount')}`} />
                </Form.Item>
              </Col>
              <Col className="w-12">
                <Form.Item name="IsLockMemberGroup">
                  <Select
                    className="w-full"
                    placeholder={i18n.t('lockMemberGroup')}
                    options={[
                      ...common.enumToOptions(MEMBER_GROUP_LOCK_STATUS_YES_NONE)
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
              <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>

            </Row>
            <Row gutter={[16, 16]} className="mt-1" justify="space-between">
              <Col>
                {
                  <Row align="middle" gutter={[12, 12]}>
                    {
                      $p('11702') &&
                      <Col>
                        <Select
                          className="w-8"
                          placeholder={i18n.t('memberGroup')}
                          value={selectTargetGroupId}
                          disabled={selectedRowKeys.length === 0}
                          options={dataList}
                          onChange={(selectTargetGroupId: any) => {
                            setIsModifyGroupModalOpen(true);
                            setSelectTargetGroupId(selectTargetGroupId)
                            const selectTargetGroup = dataList.find((option: any) => option.value === selectTargetGroupId);
                             setSelectTargetGroupName(selectTargetGroup?.label)
                          }}
                        />
                      </Col>
                    }
                    {
                      $p('11703') &&
                      <Col>
                        <Select
                          className="w-8"
                          placeholder={i18n.t('lockMemberGroup')}
                          value={lockStatus}
                          disabled={selectedRowKeys.length === 0}
                          options={enumToOptions(MEMBER_GROUP_LOCK_STATUS)}
                          onChange={(status: number) => {
                            setIsModalOpen(true);
                            setLockStatus(status)
                          }}
                        />
                      </Col>
                    }
                  </Row>
                }
              </Col>
            </Row>

            <Table
              loading={isValidating}
              size="middle"
              className="mt-1"
              rowSelection={{
                selectedRowKeys,
                onChange: onSelectChange
              }}
              dataSource={memberGroupMemberList?.Data?.map((item: any) => ({ key: item.Id, ...item })) || []}
              columns={columns}
              pagination={false}
            />
            <LayoutPagination total={memberGroupMemberList && memberGroupMemberList.TotalRecord} setPage={setPage} page={page} />
          </Form>
        </Content>
      </div>
      <InquiryWindow isOpen={isModifyGroupModalOpen} close={() => handleCancelModifyGroup()}
                     closeWithoutReset={selectedIsLock.includes(MEMBER_GROUP_LOCK_STATUS.lock) ? handleCancelModifyGroupNoResetTargetGroupId : false}
                     msg={`${i18n.t('confirmToChangeMemberGroupTo')} ${selectTargetGroupName}`}
                     action={handleModifyGroup} />
      <InquiryWindow isOpen={isModalOpen} close={() => handleCancelStatus()}
                     msg={`${i18n.t('sure')} ${i18n.t(MEMBER_GROUP_LOCK_STATUS[lockStatus])} ${selectedRowKeys.length} ${i18n.t('membershipAccounts')}`}
                     action={handleLockStatus} />
      <PopupModifyGroupsFail
        isOpen={isModalOpenModifyGroupsFail}
        close={() => {
          handleCancelModifyGroup()
          setIsModalOpenModifyGroupsFail(false);
        }}
        lockedAccount={lockedAccount}
        handleModifyGroupCallApi={handleModifyGroupCallApi}
        selectedUnLockedAccountsId={selectedUnLockedAccountsId}/>
    </>
  );
};

export default PageMain;