import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Descriptions, Form, Input, InputNumber, Row, Select, Space, Spin, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import { FormInputPosition, FormInputPromotionAmount } from 'components/form.component';
import { DateSelector, LayoutNav, UploadImage } from 'components/layout.component';
import { MemberLevelSelect } from 'components/member.component';
import { CKEditorComp } from 'components/promotion.component';
import { RESPONSE_CODE_BONUS } from 'constants/response';
import { AMOUNT_MAX } from 'constants/system';
import dayjs from 'dayjs';
import { PROMOTION_STATE } from 'enum/promotion';
import { WHETHER } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import { enumToOptions, timeL2S, timeS2L, tzShift, verify } from "utils/common";

// 優惠活動管理 編輯每日簽到

const PageMain: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState<string>();
  const [isPopUp, setIsPopUp] = useState();
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    null
  ]);

  const { data: checkInBonus } = $get({ url: 'api/bonus/checkin/info', params: { BonusId: id } })
  const { data: memberLevel, isValidating } = $get({
    url: 'api/memberlevel/bonus/singin/list',
    params: { BonusId: id }
  });

  useEffect(() => {
    if (checkInBonus) {
      const DaileyRule = JSON.parse(checkInBonus.Data.BonusRuleSetting);
      const LevelsId = JSON.parse(checkInBonus.Data.LevelsId);
      form.setFieldsValue({
        promotionName: checkInBonus.Data.Name,
        LevelId: LevelsId,
        day01: DaileyRule.Dailey.Amount[0].Amount,
        day02: DaileyRule.Dailey.Amount[1].Amount,
        day03: DaileyRule.Dailey.Amount[2].Amount,
        day04: DaileyRule.Dailey.Amount[3].Amount,
        day05: DaileyRule.Dailey.Amount[4].Amount,
        day06: DaileyRule.Dailey.Amount[5].Amount,
        day07: DaileyRule.Dailey.Amount[6].Amount,
        cycleAmount01: DaileyRule.Dailey.CycleAmount,
        requiresContinuousSignIn: DaileyRule.IsContinuous,

        day10: DaileyRule.Cumulative.Amount[0].Amount,
        day20: DaileyRule.Cumulative.Amount[1].Amount,
        day30: DaileyRule.Cumulative.Amount[2].Amount,
        day40: DaileyRule.Cumulative.Amount[3].Amount,
        day50: DaileyRule.Cumulative.Amount[4].Amount,
        day60: DaileyRule.Cumulative.Amount[5].Amount,
        cycleAmount02: DaileyRule.Cumulative.CycleAmount,
        accumulatedCycleAmount: DaileyRule.Cumulative.CycleAmount,

        rolloverRate01: checkInBonus.Data.ValidBetTimes,
        rolloverRate02: DaileyRule.Cumulative.ValidBetTimes,
        distributionMethod: checkInBonus.Data.SettleMethod,
        IsPopUp: checkInBonus.Data.IsPopUp,
        IsRedirect: checkInBonus.Data.IsRedirect,
        status: checkInBonus.Data.Status,
        Position: checkInBonus.Data.Position,
        internalContent: checkInBonus.Data.Content
      })
      setImageData(checkInBonus.Data.Photo ? 
        [{
          uid: checkInBonus.Data.Photo,
          name: checkInBonus.Data.Photo,
          url: checkInBonus.Data.Photo,
        }] : []
      )
      setEditorContent(checkInBonus.Data.Content);
      setIsPopUp(checkInBonus.Data.IsPopUp);
      setDate([timeS2L(checkInBonus.Data.StartDate), timeS2L(checkInBonus.Data.EndDate), null])
    }
  }, [checkInBonus]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/bonus/checkin/update',
      send: {
        Id: id,
        Name: formData.promotionName,
        LevelId: formData.LevelId,
        DaileyRule: {
          CycleAmount: Number(formData.cycleAmount01),
          Amount: [
            { Day: 1, Amount: Number(formData.day01) },
            { Day: 2, Amount: Number(formData.day02) },
            { Day: 3, Amount: Number(formData.day03) },
            { Day: 4, Amount: Number(formData.day04) },
            { Day: 5, Amount: Number(formData.day05) },
            { Day: 6, Amount: Number(formData.day06) },
            { Day: 7, Amount: Number(formData.day07) }
          ]
        },
        IsContinuous: Number(formData.requiresContinuousSignIn),
        DaileyValidBetTimes: Number(formData.rolloverRate01),
        CumulativeRule: {
          CycleAmount: Number(formData.cycleAmount02),
          Amount: [
            { Day: 10, Amount: Number(formData.day10) },
            { Day: 20, Amount: Number(formData.day20) },
            { Day: 30, Amount: Number(formData.day30) },
            { Day: 40, Amount: Number(formData.day40) },
            { Day: 50, Amount: Number(formData.day50) },
            { Day: 60, Amount: Number(formData.day60) }
          ],
        },
        CumulativeValidBetTimes: Number(formData.rolloverRate02),
        IsPopUp: formData.IsPopUp,
        IsRedirect: formData.IsRedirect,
        Status: formData.status,
        Position: formData.Position,
        StartDate: timeL2S(date[0] as string),
        EndDate: timeL2S(date[1] as string),
        Photo: imageData[0] ? imageData[0].url : '',
        Content: editorContent,
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        navigate('/promotion');
        setLoading(false);
      },
      resCode: RESPONSE_CODE_BONUS
    }, setLoading)
  }

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  return (
    <div id="container">
      <Content className="ph-2">
        <Spin spinning={isValidating}>
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
          distributionMethod: 0,
          Position: 0
        }}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Form.Item name="promotionName" label={i18n.t('promotionName')} rules={[{ required: true, message: `${i18n.t('required')}` },
                  { type: 'string', max: 100, message: `${i18n.t('promotionActivityNameLimitedTo100Characters')}` }]}>
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Space size="middle">
                    <Button onClick={() => navigate('/promotion')}>{i18n.t('cancel')}</Button>
                    <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col>
                  {/*required={false}用於移除antd預設紅色**/}
                  <Form.Item required={false} name="LevelId" style={{ minWidth: '12rem' }} rules={[{ required: true, message: `${i18n.t('required')}` }]} label={
                    <span>
                      <div><span className="require">*</span>{i18n.t("memberLevel")}</div>
                      <div className="color-03">{`(${i18n.t("selectableMultiple")})`}</div>
                    </span>
                  }>
                    <MemberLevelSelect mode="multiple" name="LevelId" form={form} bonusData={memberLevel?.Data} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 優惠金額 */}
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="promotionAmount" label={i18n.t('promotionAmount')} required>
                    <Descriptions size="small" layout="vertical" column={8} bordered>
                      <Descriptions.Item label={'Day 1'}>
                        <FormInputPromotionAmount name="day01" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 2'}>
                        <FormInputPromotionAmount name="day02" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 3'}>
                        <FormInputPromotionAmount name="day03" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 4'}>
                        <FormInputPromotionAmount name="day04" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 5'}>
                        <FormInputPromotionAmount name="day05" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 6'}>
                        <FormInputPromotionAmount name="day06" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 7'}>
                        <FormInputPromotionAmount name="day07" />
                      </Descriptions.Item>
                      <Descriptions.Item label={
                        <div>
                          {i18n.t('cycleAmount')}
                          <Tooltip placement="top" title={i18n.t('startOnDay8')}>
                            <InfoCircleFilled style={{ marginLeft: 2 }} />
                          </Tooltip>
                        </div>
                      }>
                        <Form.Item name="cycleAmount01" rules={verify({ point: 4, max: AMOUNT_MAX })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="requiresContinuousSignIn" label={i18n.t('requiresContinuousSignIn')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="rolloverRate01" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
                    <InputNumber className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 連續累積金額 */}
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="accumulatedAmount" label={i18n.t('accumulatedAmount')} required>
                    <Descriptions size="small" layout="vertical" column={7} bordered>
                      <Descriptions.Item label={'Day 10'}>
                        <FormInputPromotionAmount name="day10" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 20'}>
                        <FormInputPromotionAmount name="day20" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 30'}>
                        <FormInputPromotionAmount name="day30" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 40'}>
                        <FormInputPromotionAmount name="day40" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 50'}>
                        <FormInputPromotionAmount name="day50" />
                      </Descriptions.Item>
                      <Descriptions.Item label={'Day 60'}>
                        <FormInputPromotionAmount name="day60" />
                      </Descriptions.Item>
                      <Descriptions.Item label={
                        <div>
                          {i18n.t('accumulatedCycleAmount')}
                          <Tooltip placement="top" title={i18n.t('startOnDay70')}>
                            <InfoCircleFilled style={{ marginLeft: 2 }} />
                          </Tooltip>
                        </div>
                      }>
                        <Form.Item name="cycleAmount02" rules={verify({ point: 4, max: AMOUNT_MAX })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="rolloverRate02" label={i18n.t('consecutiveAccumulatedAmountWageringMultiplier')}
                    rules={verify({ point: 0 })}>
                    <InputNumber className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="distributionMethod" label={i18n.t('distributionMethod')} required>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      options={[
                        { value: 0, label: `${i18n.t('systemDistribution')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="IsPopUp" label={i18n.t('popupDisplay')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      onChange={e => {
                        setIsPopUp(e);
                        form.setFieldValue('IsRedirect', WHETHER.none);
                      }}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="IsRedirect" label={i18n.t('redirectToPromotionPage')}
                    rules={[{ required: isPopUp, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      disabled={!isPopUp}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="status" label={i18n.t('status')} rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(PROMOTION_STATE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <FormInputPosition className="w-12" />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item name="promotionTime" label={`${i18n.t('promotionStartTime')} - ${i18n.t('promotionEndTime')}`}
                rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                <DateSelector form={form} name="promotionTime" width={396} date={date} setDate={setDate} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="Photo" valuePropName="fileList" label={i18n.t('image')} 
                rules={[{ required: !imageData[0], message: `${i18n.t('required')}` }]} required>
                <UploadImage
                  name="Photo"
                  url={'/upload/images/public/bonus'}
                  preUrl={checkInBonus?.Data?.Photo || ''}
                  imageData={imageData}
                  setImageData={setImageData}
                  form={form}
                  remove
                  w={1920}
                  h={700}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="internalContent" label={i18n.t('internalContent')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'internalContent'} image />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        </Spin>
      </Content>
    </div >
  );
};

export default PageMain;