import PagePromotion from "pages/admin/promotion";
import PagePromotionAddCoupon from "pages/admin/promotion/add-coupon";
import PagePromotionAddJackpot from "pages/admin/promotion/add-jackpot";
import PagePromotionAddMission from "pages/admin/promotion/add-mission";
import PagePromotionAddOther from "pages/admin/promotion/add-other";
import PagePromotionAddRedEnvelope from "pages/admin/promotion/add-red-envelope";
import PagePromotionAddStore from "pages/admin/promotion/add-store";
import PagePromotionCoupon from "pages/admin/promotion/coupon";
import PagePromotionCouponSearch from "pages/admin/promotion/coupon-search";
import PagePromotionSearch from "pages/admin/promotion/search";
import PagePromotionEditRecommend from "pages/admin/promotion/edit-recommend";
import PagePromotionEditCoupon from "pages/admin/promotion/edit-coupon";
import PagePromotionEditJackpot from "pages/admin/promotion/edit-jackpot";
import PagePromotionEditMission from "pages/admin/promotion/edit-mission";
import PagePromotionEditOther from "pages/admin/promotion/edit-other";
import PagePromotionEditRedEnvelope from "pages/admin/promotion/edit-red-envelope";
import PagePromotionEditStore from "pages/admin/promotion/edit-store";
import PagePromotionJackpot from "pages/admin/promotion/jackpot";
import PagePromotionJackpotSearch from "pages/admin/promotion/jackpot-search";
import PagePromotionLevel from "pages/admin/promotion/level";
import PagePromotionRebateSearch from "pages/admin/promotion/rebate-search";
import PagePromotionRebate from "pages/admin/promotion/rebate";
import PagePromotionLevelSearch from "pages/admin/promotion/level-search";
import PagePromotionEditSignupBonus from "pages/admin/promotion/edit-signup-bonus";

export const promotionModule = [
  { path: '/promotion', element: <PagePromotion/> },
  { path: '/promotion/search', element: <PagePromotionSearch/> },
  { path: '/promotion/edit-recommend', element: <PagePromotionEditRecommend/> },
  { path: '/promotion/add-mission', element: <PagePromotionAddMission/> },
  { path: '/promotion/edit-mission/:id', element: <PagePromotionEditMission/> },
  { path: '/promotion/add-other', element: <PagePromotionAddOther/> },
  { path: '/promotion/edit-other/:id', element: <PagePromotionEditOther/> },
  { path: '/promotion/rebate', element: <PagePromotionRebate/> },
  { path: '/promotion/rebate-search', element: <PagePromotionRebateSearch/> },
  { path: '/promotion/level', element: <PagePromotionLevel/> },
  { path: '/promotion/level-search', element: <PagePromotionLevelSearch/> },
  { path: '/promotion/add-store', element: <PagePromotionAddStore/> },
  { path: '/promotion/edit-store/:id', element: <PagePromotionEditStore/> },
  { path: '/promotion/add-red-envelope', element: <PagePromotionAddRedEnvelope/> },
  { path: '/promotion/edit-red-envelope/:id', element: <PagePromotionEditRedEnvelope/> },
  { path: '/promotion/jackpot', element: <PagePromotionJackpot/> },
  { path: '/promotion/add-jackpot', element: <PagePromotionAddJackpot/> },
  { path: '/promotion/edit-jackpot/:id', element: <PagePromotionEditJackpot/> },
  { path: '/promotion/jackpot-search', element: <PagePromotionJackpotSearch/> },
  { path: '/promotion/coupon', element: <PagePromotionCoupon/> },
  { path: '/promotion/add-coupon', element: <PagePromotionAddCoupon/> },
  { path: '/promotion/edit-coupon/:id', element: <PagePromotionEditCoupon/> },
  { path: '/promotion/coupon-search', element: <PagePromotionCouponSearch/> },
  { path: '/promotion/edit-signup-bonus', element: <PagePromotionEditSignupBonus/> },
]