import { Button, Col, Form, Image, Input, Modal, Row, Select, Switch, Table, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { GATEWAY_CODE_WITHDRAW } from "constants/gateway";
import { RESPONSE_CODE_BANKCARD } from "constants/response";
import { MEMBER_BANK_STATE, MODE } from "enum/state";
import useAccount from "hooks/account.hook";
import useSite from "hooks/site.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { $get, $post } from "services";
import * as common from 'utils/common';
import { InquiryWindow } from "./layout.component";

// 會員銀行卡列表

export const ViewBank = ({ id }: any) => {
  const { permissions: $p } = useAccount();
  const { data: $s } = useSite();
  const [isOpenAddBankModal, setIsOpenAddBankModal] = useState(false);
  const [isOpenDeleteBankModal, setIsOpenDeleteBankModal] = useState(false);
  const [currentCard, setCurrentCard] = useState<any>({});
  const [mode, setMode] = useState(0);

  const { data, mutate, isValidating } = $get({
    url: 'api/withdrawmethod/member/list',
    params: { MemberId: id },
    allow: !!id
  });

  const handleEditState = (record: any) => {
    // 開關
    $post({
      url: 'api/withdrawmethod/update/status',
      send: {
        Id: record.Id,
        Status: record.Status === MEMBER_BANK_STATE.verificationSuccessfulOpen
          ? MEMBER_BANK_STATE.verificationSuccessfulClose : MEMBER_BANK_STATE.verificationSuccessfulOpen
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      }
    })
  }

  const handleDelete = (record: any) => {
    setIsOpenDeleteBankModal(true);
    setCurrentCard(record);
  }

  const submitDelete = () => {
    $post({
      url: 'api/withdrawmethod/member/delete',
      send: { Id: currentCard.Id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_BANKCARD
    })
  }

  return (
    <>
      <Col span={24}>
        {
          $p('10503') &&
          <Button type="primary" onClick={() => {
            setMode(MODE.add);
            setCurrentCard({});
            setIsOpenAddBankModal(true);
          }}>{i18n.t('addBankCard')}</Button>
        }
      </Col>
      <Col span={24} className="mt-1">
        <Table
          loading={isValidating}
          size="middle"
          dataSource={
            data?.Data?.filter((item: any) => item.GatewayCode === GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK)
              .map((item: Bank) => ({ key: item.Id, ...item }))
          }
          columns={[
            {
              dataIndex: 'BankName',
              title: i18n.t('bankName'),
              width: 180
            },
            {
              dataIndex: 'Name',
              width: 180,
              title: i18n.t('accountName'),
            },
            {
              dataIndex: 'BankAccount',
              width: 180,
              title: i18n.t('accountNumber'),
            },
            {
              dataIndex: 'PhoneNumber',
              width: 180,
              hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
              title: `${i18n.t('countryCode')} - ${i18n.t('phoneNumber')}`,
              render: (val, { PhonePrefix }: any) => `${PhonePrefix} - ${val}`
            },
            {
              dataIndex: 'Branch',
              width: 200,
              hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
              title: i18n.t('branchName'),
              render: (val) => val || '-'
            },
            {
              dataIndex: 'State',
              width: 200,
              hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
              title: i18n.t('bankCounty'),
              render: (val) => val || '-'
            },
            {
              dataIndex: 'City',
              width: 200,
              hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
              title: i18n.t('city'),
              render: (val) => val || '-'
            },
            {
              dataIndex: 'Status',
              width: 130,
              title: i18n.t('status'),
              render: (val, record: any) => (
                <Switch
                  checked={val === MEMBER_BANK_STATE.verificationSuccessfulOpen}
                  onChange={v => handleEditState(record)}
                  disabled={!$p('10505') || val === MEMBER_BANK_STATE.verificationFailed || val === MEMBER_BANK_STATE.waitVerify}
                />
              )
            },
            {
              dataIndex: 'Status',
              width: 130,
              title: i18n.t('verificationStatus'),
              render: (val) =>
                <div className={common.stateColorBank(val)}>{i18n.t(MEMBER_BANK_STATE[val])}</div>
            },
            {
              title: i18n.t('operation'),
              width: 130,
              render: (_, record: any) => (
                <>
                  <Button className="size-12" type="link" onClick={() => {
                    setMode(MODE.detail);
                    setCurrentCard(record);
                    setIsOpenAddBankModal(true);
                  }}>
                    {i18n.t('detail')}
                  </Button>
                  {
                    $p('10504') &&
                    <Button className="size-12" type="link" onClick={() => handleDelete(record)}>
                      {i18n.t('delete')}
                    </Button>
                  }
                </>
              )
            },
          ]}
          pagination={false}
        />
        <PopupBankModal isOpen={isOpenAddBankModal} close={() => setIsOpenAddBankModal(false)} mutate={mutate} mode={mode} bankId={currentCard.Id} />
        {/* 聖經詢問視窗 */}
        <InquiryWindow isOpen={isOpenDeleteBankModal} close={() => setIsOpenDeleteBankModal(false)}
          msg={`${i18n.t('confirmDeletion')} ${currentCard.BankName} ${i18n.t('isData')}`} action={submitDelete} />
      </Col>
    </>
  )
}
// 銀行新增詳情
export const PopupBankModal = ({ isOpen, close, mutate, mode, bankId }: any) => {
  const { data: $s, bankCode2Name } = useSite();
  // 銀行名稱列表
  const { data: _getBankNameList } = $get({
    url: `api/bank/${$s?.Currency}/list`,
    allow: !!($s?.Currency)
  })
  const { id } = useParams();
  const [form] = useForm();

  const disabled = mode === MODE.detail;
  const isVietnam = $s?.Currency === 'VND' || $s?.Currency === 'kVND';
  const isThailand = $s?.Currency === 'THB';

  const { data, mutate: refreshBankInfo } = $get({
    url: 'api/withdrawmethod/one',
    params: { Id: bankId },
    allow: !!bankId && (mode === MODE.edit || mode === MODE.detail)
  });
  useEffect(() => {
    if (data && isOpen) {
      refreshBankInfo();
      form.setFieldsValue({
        BankCode: data.Data.BankName,
        Account: data.Data.Account,
        Name: data.Data.Name,
        Branch: data.Data.Branch,
        State: data.Data.State,
        City: data.Data.City,
        PhonePrefix: data.Data.PhonePrefix ? data.Data.PhonePrefix.replace('+', '') : '',
        PhoneNumber: data.Data.PhoneNumber,
      });
    }
  }, [data, isOpen]);

  const handleSubmit = (formData: any) => {
    const send: any = {
      BankCode: formData.BankCode,
      BankName: bankCode2Name(formData.BankCode),
      Account: formData.Account,
      Name: formData.Name,
      Branch: formData.Branch,
      State: formData.State,
      City: formData.City,
      PhonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : '',
      PhoneNumber: formData.PhoneNumber,
    }
    if (mode === MODE.add) {
      send.OwnerId = id;
      $post({
        url: 'api/withdrawmethod/member/bank/create',
        send,
        success: () => {
          message.success(i18n.t('updateSuccess'));
          handleCancel();
          mutate();
        },
        resCode: RESPONSE_CODE_BANKCARD
      })
    } else {
      send.Id = data?.Data.Id;
      $post({
        url: 'api/withdrawmethod/member/update',
        send,
        success: () => {
          message.success(i18n.t('updateSuccess'));
          handleCancel();
          mutate();
        },
        resCode: RESPONSE_CODE_BANKCARD
      })
    }
  }

  const handleCancel = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={mode === MODE.add ? i18n.t('addBankCard') : i18n.t('bankCard')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Form.Item name="BankCode" label={i18n.t('bankName')} rules={[{ required: true }]}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase()) || 
                  `${(option?.value ?? '')}`.toLowerCase().includes(input.toLowerCase())
                }
                className="w-full"
                placeholder={`${i18n.t('inputData')}`}
                options={_getBankNameList?.Data.map((item: any) => {
                  return { value: item.Code, label: i18n.t(item.Name) }
                })}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Account" label={i18n.t('accountNumber')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Name" label={i18n.t('bankAccountName')}
              rules={[{ required: true, type: 'string', max: 100 }]}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          {!isVietnam &&
            <>
              {/* 分行 */}
              <Col span={24}>
                <Form.Item name="Branch" label={i18n.t('branchName')} rules={[{ required: !isThailand }]}>
                  <Input placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="State" label={i18n.t('bankCounty')}>
                  <Input placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="City" label={i18n.t('city')}>
                  <Input placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="PhonePrefix" label={i18n.t('phoneNumber')}
                  rules={common.verify({ point: 7, required: false })}>
                  <Row>
                    <Col span={9}>
                      <Input autoComplete="off" disabled={disabled} placeholder={`${i18n.t('enterCountryCode')}`} maxLength={50} addonBefore={'+'} />
                    </Col>
                    <Col span={15}>
                      <Input disabled={disabled} placeholder={`${i18n.t('inputMobile')}`} maxLength={50} />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </>
          }
        </Row>
        <Row justify="center" className="pt-1">
          <Button onClick={handleCancel}>{i18n.t('cancel')}</Button>
          {
            !disabled &&
            <Button className="ml-1" type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
          }
        </Row>
      </Form>
    </Modal>
  )
}

// 會員虛擬貨幣列表
export const ViewVirtualCurrency = ({ id }: any) => {
  const { permissions: $p } = useAccount();
  const [isOpenAddBankModal, setIsOpenAddBankModal] = useState(false);
  const [isOpenDeleteBankModal, setIsOpenDeleteBankModal] = useState(false);
  const [currentCard, setCurrentCard] = useState<any>({});
  const [mode, setMode] = useState(0);

  const { data, mutate, isValidating } = $get({
    url: 'api/withdrawmethod/member/list',
    params: { MemberId: id },
    allow: !!id
  });

  const handleEdit = (record: any) => {
    setIsOpenAddBankModal(true);
    setCurrentCard(record);
    setMode(MODE.edit);
  }

  const handleEditState = (record: any) => {
    // 開關
    $post({
      url: 'api/withdrawmethod/update/status',
      send: {
        Id: record.Id,
        Status: record.Status === MEMBER_BANK_STATE.verificationSuccessfulOpen
          ? MEMBER_BANK_STATE.verificationSuccessfulClose : MEMBER_BANK_STATE.verificationSuccessfulOpen
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      }
    })
  }

  const handleDelete = (record: any) => {
    setIsOpenDeleteBankModal(true);
    setCurrentCard(record);
  }

  const submitDelete = () => {
    $post({
      url: 'api/withdrawmethod/member/delete',
      send: { Id: currentCard.Id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_BANKCARD
    })
  }

  return (
    <>
      <Col span={24} className="mt-1">
        <Table
          loading={isValidating}
          size="middle"
          dataSource={
            data?.Data?.filter((item: any) => item.GatewayCode === GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_CRYPTO)
              .map((item: Bank) => ({ key: item.Id, ...item }))
          }
          columns={[
            {
              dataIndex: 'Crypto',
              title: i18n.t('currency'),
              width: 360,
            },
            {
              dataIndex: 'CryptoType',
              title: i18n.t('networkType'),
              width: 360,
            },
            {
              dataIndex: 'CryptoAddress',
              title: i18n.t('userAddress'),
              width: 400,
            },
            {
              dataIndex: 'Photo',
              title: i18n.t('QRCode'),
              width: 200,
              render: (val) => (val && JSON.parse(val)[0])
                ? <Image className="center" src={JSON.parse(val)[0]} width={25} height={25} /> : '-'
            },
            {
              dataIndex: 'Status',
              title: i18n.t('status'),
              width: 130,
              render: (val, record: any) => (
                 <Switch
                  checked={val === MEMBER_BANK_STATE.verificationSuccessfulOpen}
                  onChange={v => handleEditState(record)}
                  disabled={!$p('10505') || val === MEMBER_BANK_STATE.verificationFailed || val === MEMBER_BANK_STATE.waitVerify}
                />
              )
            },
            {
              dataIndex: 'Status',
              title: i18n.t('verificationStatus'),
              width: 130,
              render: (val) => (
                <div className={common.stateColorBank(val)}>{i18n.t(MEMBER_BANK_STATE[val])}</div>
              )
            },
            {
              title: i18n.t('operation'),
              width: 130,
              render: (_, record: any) => (
                <>
                  <Button className="size-12" type="link" onClick={() => {
                    setMode(MODE.detail);
                    setCurrentCard(record);
                    setIsOpenAddBankModal(true);
                  }}>
                    {i18n.t('detail')}
                  </Button>
                  {$p('10504') &&
                  <Button className="size-12" type="link" onClick={() => handleDelete(record)}>
                    {i18n.t('delete')}
                  </Button>}
                </>
              )
            },
          ]}
          pagination={false}
        />
        <PopupVirtualModal isOpen={isOpenAddBankModal} close={() => setIsOpenAddBankModal(false)} mutate={mutate} mode={mode} bankId={currentCard.Id} />
        <InquiryWindow isOpen={isOpenDeleteBankModal} close={() => setIsOpenDeleteBankModal(false)}
          msg={`${i18n.t('confirmDeletion')} ${currentCard.Crypto} ${i18n.t('isData')}`} action={submitDelete} />
      </Col>
    </>
  )
}

// 虛擬貨幣新增詳情
export const PopupVirtualModal = ({ isOpen, close, mutate, mode, bankId }: any) => {

  const { id } = useParams();
  const [form] = useForm();

  const disabled = mode === MODE.detail

  const { data, mutate: refreshBankInfo } = $get({
    url: 'api/withdrawmethod/one',
    params: { Id: bankId },
    allow: !!bankId && (mode === MODE.edit || mode === MODE.detail)
  });
  useEffect(() => {
    if (data && isOpen) {
      refreshBankInfo();
      form.setFieldsValue({
        Crypto: data?.Data.Crypto,
        CryptoType: data?.Data.CryptoType,
        CryptoAddress: data?.Data.CryptoAddress,
      });
    }
  }, [data, isOpen]);

  // BO沒有新增虛擬貨幣
  const handleSubmit = async (formData: any) => {

  }

  const handleCancel = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('virtualCurrency')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Crypto" label={i18n.t('currency')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CryptoType" label={i18n.t('networkType')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CryptoAddress" label={i18n.t('userAddress')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" className="pt-1">
          <Button onClick={handleCancel}>{i18n.t('cancel')}</Button>
          {
            !disabled &&
            <Button className="ml-1" type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
          }
        </Row>
      </Form>
    </Modal>
  )
}
